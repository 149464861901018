<template>
  <v-scale-transition>
    <panel
      :breadcrumbs="breadcrumbs"
      :title="title"
    >
      <v-card>
        <v-toolbar card>
          <v-menu
            v-model="menu"
            close-on-content-click
            transition="scale-transition"
            min-width="290px"
          >
            <v-text-field
              slot="activator"
              v-model="start"
              label="Select start date"
              persistent-hint
              prepend-icon="event"
              readonly
            />

            <v-date-picker
              ref="picker"
              v-model="start"
              :show-current="false"
              actions
              no-title
            />
          </v-menu>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <v-menu
            v-model="menu1"
            close-on-content-click
            transition="scale-transition"
            min-width="290px"
          >
            <v-text-field
              slot="activator"
              v-model="end"
              label="Select end date"
              persistent-hint
              prepend-icon="event"
              readonly
            />

            <v-date-picker
              ref="picker"
              v-model="end"
              :show-current="false"
              actions
              no-title
            />
          </v-menu>
          <!-- <v-tooltip bottom>
            <v-btn
              slot="activator"
              icon
              @click="showChart = !showChart"
            >
              <v-icon>{{ showChart ? 'cancel' : 'mdi-chart-bar' }}</v-icon>
            </v-btn>
            <span>{{ showChart ? 'Hide chart' : 'Show chart' }}</span>
          </v-tooltip> -->
        </v-toolbar>
        <!-- <v-slide-y-transition>
          <v-card-text v-show="showChart">
            <chart
              :options="chartOptions"
              :series="series"
              type="area"
            />
          </v-card-text>
        </v-slide-y-transition> -->
        <v-data-table
          :headers="headers"
          :items="items"
          hide-actions
          class="mt-3 elevation-1"
        >
          <template #items="props">
            <tr>
              <td class="text-xs-center">
                {{ props.item.day }}
              </td>
              <td class="text-xs-center info">
                {{ props.item.dealer }}
              </td>
              <td class="text-xs-center info">
                {{ props.item.subscriber }}
              </td>
            </tr>
          </template>
          <template slot="footer">
            <tr>
              <td class="text-xs-right">
                Total:
              </td>
              <td class="text-xs-center info">
                {{ totalDealer }}
              </td>
              <td class="text-xs-center info">
                {{ totalSubscriber }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
      <p />
      <v-toolbar
        class="mt-3"
        flat
      >
        <v-toolbar-title>Top 30 Activation</v-toolbar-title>
        <v-divider
          class="mx-2"
          inset
          vertical
        />
        <v-spacer />
      </v-toolbar>
      <v-data-table
        :headers="masterHeaders"
        :items="items1"
        hide-actions
        disable-initial-sort
        class="elevation-1"
      >
        <template #items="props">
          <tr>
            <td class="text-xs-left">
              {{ props.item.dealer }}
            </td>
            <td class="text-xs-left">
              {{ props.item.nama }}
            </td>
            <td class="text-xs-center info">
              {{ props.item.total }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </panel>
  </v-scale-transition>
</template>

<script>
import { DateTime } from 'luxon'
import { createGetParams } from '@/rest'

const title = 'Weekly User Statistics'
// import apexcharts from '@/mixins/apexcharts'
export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  // mixins: [apexcharts],
  data () {
    return {
      expand: true,
      start: null,
      end: null,
      startDate: null,
      endDate: null,
      day: null,
      showChart: false,
      today: DateTime.local().setZone('Asia/Kuala_Lumpur').toFormat('yyyy-LL-dd'),
      breadcrumbs: [
        {
          text: 'User Statistics', disabled: false, to: '/support/user_statistics',
        },
        {
          text: title, disabled: true,
        },
      ],
      items: [],
      headers: [
        {
          text: 'Daily',
          align: 'center',
          sortable: true,
          value: 'start',
        },
        {
          text: 'Dealer',
          align: 'center',
          sortable: true,
          value: 'dealer',
        },
        {
          text: 'Subscriber',
          align: 'center',
          sortable: true,
          value: 'subscriber',
        },
      ],
      items1: [],
      masterHeaders: [
        {
          text: 'Dealer',
          align: 'left',
          sortable: true,
          value: 'dealer',
        },
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'nama',
        },
        {
          text: 'Total Activation',
          align: 'center',
          sortable: true,
          value: 'total',
        },
      ],
      title: 'Weekly User Statistics',
      totalDealer: 0,
      totalSubscriber: 0,
      maxDate: '',
      menu: false,
      menu1: false,
    }
  },

  computed: {
    // series: function () {
    //   const series = [
    //     { name: 'Dealer', data: [] },
    //     { name: 'Subscriber', data: [] },
    //   ]

    //   for (let i = 0; i < this.filteredItems.length; i++) {
    //     const timestamp = DateTime.fromSQL(this.filteredItems[i].date).toMillis()
    //     series[0].data.push([timestamp, this.filteredItems[i].dealer])
    //     series[1].data.push([timestamp, this.filteredItems[i].susbcriber])
    //   }

    //   return series
    // },

  },

  watch: {
    xox: function (val) {
      this.onex = !val
    },
    start: function (val) {
      this.getActivations(val)
    },
    end: function (val) {
      this.getActivations(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.start = now.toFormat('yyyy-LL-dd')
    this.maxDate = this.end = now.toFormat('yyyy-LL-dd')
  //   this.chartOptions.chart.type = 'bar'
  //   this.chartOptions.chart.stacked = true
  //   this.chartOptions.xaxis.type = 'category'
  //   this.chartOptions.xaxis.category = [
  //     'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  //     'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  //   ]
  //   this.chartOptions.xaxis.labels.formatter = val => this.chartOptions.xaxis.category[val - 1]
  },
  methods: {
    getActivations: function () {
      const params = createGetParams({ startDate: this.start, endDate: this.end })
      this.$rest.get('getUserStatisticsWeekly.php', params)
        .then(response => {
          this.items = response.data.items
          this.totalDealer = response.data.totalDealer
          this.totalSubscriber = response.data.totalSubscriber
          this.items1 = response.data.items1
        }).catch(e => {
          // ignore
        })
    },
  },
}
</script>
